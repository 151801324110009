<template>
  <div>
    <b-container>
      <b-button-group>
        <router-link class="btn btn-light" :to="{ name: 'items' }">{{
          $t("items.all")
        }}</router-link>
        <router-link class="btn btn-light" :to="{ name: 'itemnew' }">{{
          $t("items.new")
        }}</router-link>
      </b-button-group>

      <br />
      <router-view class="mt-4"></router-view>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "item"
};
</script>
